<template>      
	<form @submit.prevent="checkForm" class="mb-4">
		<div class="row">
			<div class="col-12" v-if="form_message !== ''">
				<ErrorAlert :messageI18n="form_message" />
			</div>
		</div>
		<template v-if="invoice_ids">			
			
			<div class="form-group">
				<label>{{ $t('invoice.contenu_email') }}</label>
				<b-form-textarea
					id="content_mail"
					v-model="content_mail"
					rows="5"
					:class="{ 'is-invalid': errors && errors.indexOf('Content email') > -1 }"
				>
				</b-form-textarea>
			</div>

			<div class="row">
				<div class="col">
					<b-form-checkbox v-model="set_copie" >
						{{ $t('invoice.recevoir_copie') }}
					</b-form-checkbox>
				</div>
			</div>

		</template>
		<template v-else>
			<ErrorAlert :messageI18n="invoice.choose_invoice_to_send_email" />
		</template>
	</form>
</template>

<script type="text/javascript">
	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
	import Invoice from "@/mixins/Invoice.js"


	export default {
		name: "SendInvoice",
		mixins: [Config, TableAction, Navigation, Invoice],
		props: ['ready', 'invoice_ids'],
		data () {
			return {
				form_message: '',
				processing: false,
				set_copie: false,
				content_mail: this.getTrad('invoice.email_content'),
				errors: []
			}
		},
		mounted() {
			this.init_component()
		},
		created () {
		},
		methods: {
			async init_component() {
				this.$emit('update:ready', true)
			},
			async checkForm(){

			},

			async sendInvoice(){
				this.errors = []

				if(!this.processing)
				{
					this.processing = true
				
					this.$emit('update:processing', true)

					if(this.content_mail.trim() == "")
					{
						this.errors.push("Content email")
					}

					if(this.errors.length > 0)
					{
						this.processing = false
						this.$emit('update:processing', false)
						return false
					}


					let result = null
					result = await this.sendMailInvoices(this.invoice_ids, this.content_mail, this.set_copie)
					if(result)
					{
						this.$emit('update:processing', false)
						this.processing = false
						this.successToast()
						return true
					}
					else
					{
						this.processing = false
						this.$emit('update:processing', false)
					}
				}
			},


		},
		computed: {

		},
		watch:{
						
		},
		components: {
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
		}
	}
</script>